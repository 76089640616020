$(function() {


	if($(".jobs-wrapper").length > 0) {
		let query = "",
			search = "",
			count = "",
			searchVal = "",
			idCounter = 0,
			jobsPerPageStart = 0,
			inProgress = false,
			sURLVariables = [];

		function loadJobs(start) {
			if (searchVal.length == 0 || isNaN(searchVal)) {
				if (top.location.hash.indexOf('offers=') != -1) {
					start = 0;
					count = 500;
				}
				count = 30;
			} else {
				start = 0;
				count = 500;
			}
			idCounter = 0;

			if (top.location.hash.indexOf('offers=') != -1) {
				sURLVariables = top.location.hash.split('offers=').pop().split(',');
			}

			if( start == 0 ) $("#ajax-jobs-offer .jobs-grid").empty();

			if (inProgress != true) {
				$.ajax(proxyUrl + "public/vacancy?marker=jobList&start=" + start + "&count=" + count + "&sort=-dateLastPublished&query=" + encodeURIComponent(query), {
						method: 'GET',
						beforeSend: function (request) {
							request.setRequestHeader("Ocp-Apim-Subscription-Key", authorizationKey);
							inProgress = true;
							$("#ajax-jobs-offer").addClass('loading');
						},
						success: function (response) {
							if (start >= response.total) {
								$(".load-more").attr("disabled", true);
							} else {
								$(".load-more").removeAttr("disabled");
							}
							if (response.total == 0) {
								$(".jobs-list-wrapper .no-offer").show();
								$(".open-positions i").text(response.total);
							} else {
								$(".jobs-list-wrapper .no-offer").hide();
								if (response.total <= 30) {
									$(".jobs-list-wrapper .load-more").hide();
								} else {
									$(".jobs-list-wrapper .load-more").show();
								}
								if (response.count > 0) {
									for (let i = 0; i < response.data.length; i++) {
										let item = response.data[i];
										let color = 3;
										if (i % 3 == 0) {
											color = 1;
										} else if (i % 3 == 1) {
											color = 2;
										}
										let html = "";
										if (searchVal.length == 0 || isNaN(searchVal)) {
											if (top.location.hash.indexOf('offers=') != -1) {
												if (jQuery.inArray( item.id.toString(), sURLVariables) != -1) {
													++idCounter;
													html = jobOfferHtml(item, color);
												}
											} else {
												html = jobOfferHtml(item, color);
											}
										} else {
											if (item.id.toString().indexOf(searchVal) !== -1) {
												++idCounter;
												html = jobOfferHtml(item, color);
											}
										}
										$("#ajax-jobs-offer .jobs-grid").append(html);
									}
									if (searchVal.length == 0 || isNaN(searchVal)) {
										if (top.location.hash.indexOf('offers=') != -1) {
											$(".open-positions i").text(idCounter);
											$(".jobs-list-wrapper .load-more").hide();
										} else {
											$(".open-positions i").text(response.total);
										}
									} else {
										$(".open-positions i").text(idCounter);
										$(".jobs-list-wrapper .load-more").hide();

										if (idCounter == 0) {
											$(".jobs-list-wrapper .no-offer").show();
										} else {
											$(".jobs-list-wrapper .no-offer").hide();
										}
									}
								}
							}
							inProgress = false;
							$("#ajax-jobs-offer").removeClass('loading');
						},
						error: function (a, b, c) {
							console.log(a, b, c);
							inProgress = false;
							$("#ajax-jobs-offer").removeClass('loading');
							$(".jobs-list-wrapper .api-error").show();
						}
					});
			}
		}

		$(document).on("filterLoaded", function(){
			console.log("filterLoaded");
			if( $(".filter-content.loading").length > 0 ) return;

			console.log('all filtersLoaded');
			loadJobs(jobsPerPageStart);
		});

		function setFilters(changed){
			var checked = [],
				job_filter = $("#ajax-jobs-offer").attr('data-filter').split(",");

			query = "";

			if (search.length > 0) {
				query = search;
			}

			$.each( $(".filters input[type='checkbox']:checked"), function(){
				var name = $(this).attr('name'),
					value = $(this).val();
				if ($(this).parent().siblings().children("input[type='checkbox']:checked").length == 0) {
				    query += " AND ("+name+":"+value+")";
				} else {
					if ($(this).parent().prevAll().children("input[type='checkbox']:checked").length == 0 && $(this).parent().nextAll().children("input[type='checkbox']:checked").length > 0) {
						query += " AND ("+name+":"+value;
					} else if ($(this).parent().prevAll().children("input[type='checkbox']:checked").length > 0 && $(this).parent().nextAll().children("input[type='checkbox']:checked").length > 0) {
						query += " OR "+name+":"+value;
					} else if ($(this).parent().prevAll().children("input[type='checkbox']:checked").length > 0 && $(this).parent().nextAll().children("input[type='checkbox']:checked").length == 0) {
						query += " OR "+name+":"+value+")";
					}
				}
				checked.push(name+"_"+value);
			} );

			var techAreaEndpoint = proxyUrl+"public/vacancy-filter/techArea?query=" + encodeURIComponent(query),
			    remoteWorkEndpoint = proxyUrl+"public/vacancy-filter/remoteWork?query=" + encodeURIComponent(query),
				publishedCategoryEndpoint = proxyUrl+"public/vacancy-filter/publishedCategory?query=" + encodeURIComponent(query),
				stateEndpoint = proxyUrl+"public/vacancy-filter/state?query=" + encodeURIComponent(query),
				cityEndpoint = proxyUrl+"public/vacancy-filter/city?query=" + encodeURIComponent(query)
			;

			inProgress = true;
			$(".filter-content").addClass('loading');
			$("#ajax-jobs-offer").addClass('loading');
			$(".jobs-list-wrapper .api-error").hide();

			//Load AREA filters
			if( changed != "filter-tech-area"){
				$("#filter-tech-area .filter-content").empty();

				$.ajax(techAreaEndpoint, {
					method: 'GET',
					beforeSend: function(request) {
						request.setRequestHeader("Ocp-Apim-Subscription-Key", authorizationKey);
					},
					success: function(response){
						if( response.count > 0 ){
							for( var i =0; i < response.data.length; i++){
								var item = response.data[i];
								if (item.techArea != null && item.techArea != "") {
									var isChecked = checked.indexOf("techArea_\""+item.techArea+"\"") >= 0 || job_filter.indexOf(item.techArea) >=0 ? 'checked="checked"' : '';
									$("#filter-tech-area .filter-content").append(`<div class="filter-elem">
									 <input name="techArea" ${isChecked} type="checkbox" id="areachecklist${i}" value="&quot;${item.techArea}&quot;">
									  <label for="areachecklist${i}">
										<span>${item.techAreaLabel} (${item.idCount}/${item.idCountTotal})</span>
									  </label>
									</div>`);
								}
							}
						}
						$("#filter-tech-area .filter-content").removeClass('loading');
						inProgress = false;
						$("#ajax-jobs-offer").removeClass('loading');
						$(document).trigger('filterLoaded');
					},
					error: function(a,b,c){
						console.log(a,b,c);
						$(".jobs-list-wrapper .api-error").show();
					}
				});
			}else{ $("#filter-tech-area .filter-content").removeClass('loading'); }

			//Load REMOTE filters
			if( changed != "filter-remote-work"){
				$("#filter-remote-work .filter-content").empty();
				$.ajax(remoteWorkEndpoint, {
					method: 'GET',
					beforeSend: function(request) {
						request.setRequestHeader("Ocp-Apim-Subscription-Key", authorizationKey);
					},
					success: function(response){
						if( response.count > 0 ){
							for( var i =0; i < response.data.length; i++){
								var item = response.data[i];
								if (item.remoteWork != null && item.remoteWork != "") {
									var isChecked = checked.indexOf("customText12_"+item.remoteWork) >= 0 ? 'checked="checked"' : '';
									let name = item.remoteWork == "YES" ? "Tak" : "Częściowo";

									$("#filter-remote-work .filter-content").append(`<div class="filter-elem">
									 <input name="customText12" ${isChecked} type="checkbox" id="workchecklist${i}" value="${item.remoteWork}">
									  <label for="workchecklist${i}">
										<span>${item.remoteWorkLabel} (${item.idCount}/${item.idCountTotal})</span>
									  </label>
									</div>`);
								}
							}
						}
						$("#filter-remote-work .filter-content").removeClass('loading');
						inProgress = false;
						$("#ajax-jobs-offer").removeClass('loading');
						$(document).trigger('filterLoaded');
					},
					error: function(a,b,c){
						console.log(a,b,c);
						$(".jobs-list-wrapper .api-error").show();
					}
				});
			}else{ $("#filter-remote-work .filter-content").removeClass('loading'); }

			//Load CATEGORY Filters
			if( changed != "filter-published-category"){
				$("#filter-published-category .filter-content").empty();
				$.ajax(publishedCategoryEndpoint, {
					method: 'GET',
					beforeSend: function(request) {
						request.setRequestHeader("Ocp-Apim-Subscription-Key", authorizationKey);
					},
					success: function(response){
						if( response.count > 0 ){
							for( var i =0; i < response.data.length; i++){
								var item = response.data[i];
								if (item.publishedCategory.name != null && item.publishedCategory.name != "") {
									var isChecked = checked.indexOf("publishedCategory.id_"+item.publishedCategory.id) >= 0 ? 'checked="checked"' : '';
									$("#filter-published-category .filter-content").append(`<div class="filter-elem">
									 <input name="publishedCategory.id" ${isChecked} type="checkbox" id="categorychecklist${i}" value="${item.publishedCategory.id}">
									  <label for="categorychecklist${i}">
										<span>${item.publishedCategory.nameLabel} (${item.idCount}/${item.idCountTotal})</span>
									  </label>
									</div>`);
								}
							}
						}
						$("#filter-published-category .filter-content").removeClass('loading');
						inProgress = false;
						$("#ajax-jobs-offer").removeClass('loading');
						$(document).trigger('filterLoaded');
					},
					error: function(a,b,c){
						console.log(a,b,c);
						$(".jobs-list-wrapper .api-error").show();
					}
				});
			}else{ $("#filter-published-category .filter-content").removeClass('loading'); }

			//Load STATE Filters
			if( changed != "filter-state"){
				$("#filter-state .filter-content").empty();
				$.ajax(stateEndpoint, {
					method: 'GET',
					beforeSend: function(request) {
						request.setRequestHeader("Ocp-Apim-Subscription-Key", authorizationKey);
					},
					success: function(response){
						if( response.count > 0 ){
							for( var i =0; i < response.data.length; i++){
								var item = response.data[i];
								if (item.address.state != null && item.address.state != "") {
									var isChecked = checked.indexOf("address.state_"+item.address.state) >= 0 ? 'checked="checked"' : '';
									$("#filter-state .filter-content").append(`<div class="filter-elem">
									 <input name="address.state" ${isChecked} type="checkbox" id="statechecklist${i}" value="${item.address.state}">
									  <label for="statechecklist${i}">
										<span>${item.address.stateLabel} (${item.idCount}/${item.idCountTotal})</span>
									  </label>
									</div>`);
								}
							}
						}
						$("#filter-state .filter-content").removeClass('loading');
						inProgress = false;
						$("#ajax-jobs-offer").removeClass('loading');
						$(document).trigger('filterLoaded');
					},
					error: function(a,b,c){
						console.log(a,b,c);
						$(".jobs-list-wrapper .api-error").show();
					}
				});
			}else{ $("#filter-state .filter-content").removeClass('loading'); }

			//Load CITY Filters
			if( changed != "filter-city"){
				$("#filter-city .filter-content").empty();
				$.ajax(cityEndpoint, {
					method: 'GET',
					beforeSend: function(request) {
						request.setRequestHeader("Ocp-Apim-Subscription-Key", authorizationKey);
					},
					success: function(response){
						if( response.count > 0 ){
							for( var i =0; i < response.data.length; i++){
								var item = response.data[i];
								if (item.address.city != null && item.address.city != "") {
									var isChecked = checked.indexOf("address.city_"+item.address.city) >= 0 ? 'checked="checked"' : '';
									$("#filter-city .filter-content").append(`<div class="filter-elem">
									 <input name="address.city" ${isChecked} type="checkbox" id="citychecklist${i}" value="${item.address.city}">
									  <label for="citychecklist${i}">
										<span>${item.address.cityLabel} (${item.idCount}/${item.idCountTotal})</span>
									  </label>
									</div>`);
								}
							}
						}
						$("#filter-city .filter-content").removeClass('loading');
						inProgress = false;
						$("#ajax-jobs-offer").removeClass('loading');
						$(document).trigger('filterLoaded');
					},
					error: function(a,b,c){
						console.log(a,b,c);
						$(".jobs-list-wrapper .api-error").show();
					}
				});
			}else{ $("#filter-city .filter-content").removeClass('loading'); }

			jobsPerPageStart = 0;
		}

		searchItems();

		$(document).on("change", ".filters input[type='checkbox']", function(){
			setFilters( $(this).closest('.filter-category').attr('id'));
		});

		$(".jobs-list-wrapper .load-more").on( "click", function() {
			jobsPerPageStart = jobsPerPageStart + 30;
			loadJobs(jobsPerPageStart);
		});

		function searchItems() {
		    searchVal = $("#input-search").val();
			if (searchVal != '') {
			    if (isNaN(searchVal) && top.location.hash.indexOf('offers=') == -1) {
					search = " AND (title:"+$("#input-search").val()+")";
					setFilters(null);
				} else {
					search = "";
					setFilters(null);
				}
			} else {
				search = "";
				setFilters(null);
			}
			let base = $("#ajax-jobs-offer").attr('data-base'),
				searchToPath = encodeURIComponent(searchVal),
				fullPath = base + ( searchToPath != '' ? searchToPath+'/' : '' )
			;

			if( (top.location.origin + top.location.pathname) != fullPath ) {
				window.history.pushState('search', 'Title', fullPath);
			}
		}

		$("#button-search").on( "click", function() {
			searchItems();
		});

		$("#input-search").on('keyup', function (e) {
            if (e.key === 'Enter' || e.keyCode === 13) {
				searchItems();
            }
        });

		let delayTimer;
		$("#input-search").on('input', function (e) {
		    clearTimeout(delayTimer);
            delayTimer = setTimeout(function() {
				searchItems();
            }, 1000);
		});

		$(".js-display-filters").on( "click", function() {
		if ($(this).hasClass('active')) {
			$(this).siblings('.filter-content-wrapper').slideUp();
			$(this).removeClass('active');
		} else {
			$(this).siblings('.filter-content-wrapper').slideDown();
			$(this).addClass('active');
		}
		});

		$("#button-filters").on( "click", function() {
			$(".filters").addClass('show');
		});

		$("#button-filters-close").on( "click", function() {
			$(".filters").removeClass('show');
		});

		$(document).mouseup(function(e){
			var container = $(".filters.show");
			if(!container.is(e.target) && container.has(e.target).length === 0){
				container.removeClass('show');
			}
		});
	}
});
 
